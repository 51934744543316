<template>
  <div>
    <h1>{{$t($route.meta.titleKey)}}</h1>
    <div v-if="artists.length">
      <div v-for="artist in artists" :key="artist.id">
        <img :src="artist.imageUrl" width="200">
        <router-link :to="`/artists/${artist.id}`">
          {{artist.name}}
        </router-link>
      </div>
    </div>
    <div v-else>
      <span>{{$t("artists.noArtistsFound")}}</span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Artists',

  computed: {
    ...mapState([
      'artists'
    ])
  },

  created() {
    this.fetchArtists()
  },

  data() {
    return {
      // return stuff here
    }
  },

  emits: ['error'],

  methods: {
    async fetchArtists() {
      this.$store.dispatch('fetchArtists').then().catch((error) => {
        console.log(error)
        this.$emit('error', error)
      })
    }
  }
}
</script>

<style lang="scss">

</style>
