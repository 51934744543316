<template>
  <div text-center fill-height>
    <div align="center">
      <div>
        <img src="@/assets/broken-record.jpg"/>
        <h1>
          404
        </h1>
        <p>{{$t("errors.pageNotFound")}}</p>
        <a href="/">{{$t("navigation.toStart")}}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>

<style lang="scss">

</style>
