<template>
  <div>
    <h1>{{$t($route.meta.titleKey)}}</h1>
    <form id="edit-album" @submit.prevent="updateAlbum" v-if="album">
      <div>
        <label for="album-name">{{$t("title")}}</label>
        <input id="album-name" type="text" v-model.trim="albumTitle">
      </div>
      <div>
        <label for="release-date">{{$t("albums.releaseDate")}}</label>
        <input id="release-date" type="date" v-model.trim="releaseDate">
      </div>
      <div>
        <label for="tracklist">{{$t("albums.tracklist")}}</label>
        <textarea id="tracklist" rows="4" cols="50" v-model.trim="tracklist">
        </textarea>
      </div>
      <FileUploader id="album-cover" :label="$t(`albums.cover`)" :url="album.coverUrl"
        @imageSelected="(image) => albumCover = image">
      </FileUploader>
      <button type="submit">{{$t("buttons.save")}}</button>
    </form>
  </div>
</template>

<script>
import FileUploader from '@/components/FileUploader.vue'
import AlbumService from '@/services/AlbumService'

export default {
  name: 'EditAlbum',

  components: {
    FileUploader
  },

  created() {
    this.initializeRoute()
  },

  data() {
    return {
      album: null,
      albumCover: null,
      albumTitle: '',
      releaseDate: null,
      tracklist: ''
    }
  },

  emits: ['error', 'success'],

  methods: {
    fetchAlbumById(id) {
      AlbumService.fetchAlbumById(id).then((response) => {
        this.album = response.data
        this.albumTitle = this.album.title
        this.releaseDate = this.album.releaseDate
        this.tracklist = this.album.tracklist
      }).catch((error) => {
        this.$emit('error', error)
      })
    },
    initializeRoute() {
      this.resetFields()
      this.fetchAlbumById(this.$route.params.id)
    },
    resetFields() {
      this.albumCover = null
      this.albumTitle = ''
      this.releaseDate = null
      this.tracklist = ''
    },
    updateAlbum() {
      const formData = new FormData()
      formData.append('title', this.albumTitle)
      formData.append('tracklist', this.tracklist)

      if (this.albumCover) {
        formData.append('cover', this.albumCover)
      }

      if (this.releaseDate) {
        formData.append('releaseDate', this.releaseDate)
      }

      AlbumService.updateAlbum(this.album.id, formData).then(() => {
        this.initializeRoute()
        this.$emit('success', this.$i18n.t('albums.successfullyUpdated'))
      }).catch((error) => {
        this.$emit('error', error)
      })
    }
  }
}
</script>

<style lang="scss">

</style>
