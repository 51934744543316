import axios from 'axios'
/* refactor the following import when stackoverflow question is resolved: */
/* https://stackoverflow.com/questions/67125889/how-to-resolve-vue-dependency-cycle-with-axios-api-services-and-vuex */
/* until then disable lint rule */
/* eslint-disable import/no-cycle */
import store from '@/store'

export default () => axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    Authorization: `Bearer ${store.state.token}`,
    'Content-Type': 'application/json'
  }
})
