/* refactor the following import when stackoverflow question is resolved: */
/* https://stackoverflow.com/questions/67125889/how-to-resolve-vue-dependency-cycle-with-axios-api-services-and-vuex */
/* until then disable lint rule */
/* eslint-disable import/no-cycle */
import Api from '@/services/Api'

export default {
  createArtist(bodyParams) {
    return Api().post('artists', bodyParams)
  },
  fetchArtistById(artistId) {
    return Api().get(`artists/${artistId}`)
  },
  fetchArtistByIdWithAlbums(artistId) {
    return Api().get(`artists/${artistId}?include_albums=true`)
  },
  fetchArtists() {
    return Api().get('artists')
  },
  updateArtist(artistId, bodyParams) {
    return Api().patch(`artists/${artistId}`, bodyParams)
  }
}
