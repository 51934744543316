<template>
  <div>
    <h1>{{$t($route.meta.titleKey)}}</h1>
    <form id="edit-artist" @submit.prevent="updateArtist" v-if="artist">
      <div>
        <label for="artist-name">{{$t("name")}}</label>
        <input id="artist-name" type="text" v-model.trim="artistName">
      </div>
      <div>
        <label for="formation-year">{{$t("artists.formationYear")}}</label>
        <input id="formation-year" type="text" v-model.trim="formationYear">
      </div>
      <div>
        <label for="artist-biography">{{$t("artists.biography")}}</label>
        <textarea id="artist-biography" rows="4" cols="50" v-model.trim="artistBiography">
        </textarea>
      </div>
      <FileUploader id="artist-image" :label="$t(`images.singular`)" :url="artist.imageUrl"
        @imageSelected="(image) => artistImage = image">
      </FileUploader>
      <button type="submit">{{$t("buttons.save")}}</button>
    </form>
  </div>
</template>

<script>
import FileUploader from '@/components/FileUploader.vue'
import ArtistService from '@/services/ArtistService'

export default {
  name: 'EditArtist',

  components: {
    FileUploader
  },

  created() {
    this.initializeRoute()
  },

  data() {
    return {
      artist: null,
      artistBiography: '',
      artistImage: null,
      artistName: '',
      formationYear: '',
    }
  },

  emits: ['error', 'success'],

  methods: {
    fetchArtistById(id) {
      ArtistService.fetchArtistByIdWithAlbums(id).then((response) => {
        this.artist = response.data
        this.artistBiography = this.artist.biography
        this.artistName = this.artist.name
        this.formationYear = this.artist.formationYear
      }).catch((error) => {
        this.$emit('error', error)
      })
    },
    initializeRoute() {
      this.resetFields()
      this.fetchArtistById(this.$route.params.id)
    },
    resetFields() {
      this.artistBiography = ''
      this.artistImage = null
      this.artistName = ''
      this.formationYear = ''
    },
    updateArtist() {
      const formData = new FormData()
      formData.append('biography', this.artistBiography)
      formData.append('name', this.artistName)

      if (this.artistImage) {
        formData.append('image', this.artistImage)
      }

      if (this.formationYear) {
        formData.append('formationYear', this.formationYear)
      }

      ArtistService.updateArtist(this.artist.id, formData).then(() => {
        this.initializeRoute()
        this.$emit('success', this.$i18n.t('artists.successfullyUpdated'))
      }).catch((error) => {
        this.$emit('error', error)
      })
    }
  }
}
</script>

<style lang="scss">

</style>
