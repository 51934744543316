import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import routes from './routes'

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(async (to) => {
  const userIsLoggedIn = await store.dispatch('getLoginStatus')

  if (to.meta.requiresAuth && !userIsLoggedIn) {
    router.push({
      path: '/login',
      query: {
        redirect: to.fullPath
      }
    })
  }

  return true
})

export default router
