<template>
  <div>
    <h1>{{$t($route.meta.titleKey)}}</h1>
    <span>{{$t("start")}}</span>
  </div>
</template>

<script>
export default {
  name: 'Start',

  data() {
    return {
      // return stuff here
    }
  }
}
</script>

<style lang="scss">

</style>
