import Api from '@/services/Api'

export default {
  createRecord(albumId, bodyParams) {
    return Api().post(`albums/${albumId}/records`, bodyParams)
  },
  fetchRecordById(recordId) {
    return Api().get(`records/${recordId}`)
  },
  fetchRecords() {
    return Api().get('records')
  },
  updateRecord(recordId, bodyParams) {
    return Api().patch(`records/${recordId}`, bodyParams)
  }
}
