<template>
  <div>
    <div v-if="artist">
      <img :src="artist.imageUrl">
      <h1>{{artist.name}}</h1>
      <p>{{$t("artists.formationYear")}}: {{artist.formationYear}}</p>
      <p>{{$t("artists.biography")}}: {{artist.biography}}</p>
      <router-link :to="`/artists/${artist.id}/edit`" v-if="$store.state.userIsLoggedIn">
        {{$t("buttons.edit")}}
      </router-link>
      <p>{{$t("artists.albums")}}:</p>
      <div v-if="artist.Albums.length">
        <div v-for="album in artist.Albums" :key="album.id">
          <router-link :to="`/albums/${album.id}`">
            {{album.title}}
          </router-link>
        </div>
      </div>
      <div v-else>
        <span>{{$t("artists.noAlbums")}}</span>
      </div>
    </div>
    <div v-else>
      <span>{{$t("artists.artistNotFound")}}</span>
    </div>
  </div>
</template>

<script>
import ArtistService from '@/services/ArtistService'

export default {
  name: 'Artist',

  created() {
    this.fetchArtistById(this.$route.params.id)
  },

  data() {
    return {
      artist: null,
      albums: []
    }
  },

  emits: ['error'],

  methods: {
    fetchArtistById(id) {
      ArtistService.fetchArtistByIdWithAlbums(id).then((response) => {
        this.artist = response.data
        this.albums = this.artist.Albums
      }).catch((error) => {
        this.$emit('error', error)
      })
    }
  }
}
</script>

<style lang="scss">

</style>
