<template>
  <div>
    <h1>{{$t($route.meta.titleKey)}}</h1>
    <form id="register" @submit.prevent="register" autocomplete="off">
      <div>
        <label for="user-name">{{$t("users.name")}}</label>
        <input id="user-name" type="text" v-model.trim="user.name" required>
      </div>
      <div>
        <label for="user-email">{{$t("users.email")}}</label>
        <input id="user-email" type="email" v-model.trim="user.email" required>
      </div>
      <div>
        <label for="user-password">{{$t("users.password")}}</label>
        <input id="user-password" type="password" v-model.trim="user.password" autocomplete="new-password" required>
      </div>
      <div>
        <label for="confirm-password">{{$t("users.confirmPassword")}}</label>
        <input id="confirm-password" type="password" v-model.trim="user.confirmPassword" autocomplete="new-password"
          required>
      </div>
      <button type="submit">{{$t("buttons.create")}}</button>
    </form>
  </div>
</template>

<script>
import Joi from 'joi'
import UserService from '@/services/UserService'

const schema = Joi.object({
  confirmPassword: Joi.ref('password'),
  email: Joi.string()
    .trim()
    .email({ tlds: { allow: false } })
    .required(),
  name: Joi.string()
    .trim()
    .alphanum()
    .min(3)
    .required(),
  password: Joi.string()
    .trim()
    .regex(
      /* at least:
        - one uppercase letter
        - one lowercase letter
        - one digit
        - one special character
        - eight characters in length */
      new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')
    )
    .required()
})

export default {
  name: 'Register',

  data() {
    return {
      user: {
        confirmPassword: '',
        email: '',
        name: '',
        password: ''
      }
    }
  },

  emits: ['error', 'success'],

  methods: {
    register() {
      if (!this.userInputIsValid()) {
        return false
      }

      return UserService.register({
        confirmPassword: this.user.confirmPassword,
        email: this.user.email,
        name: this.user.name,
        password: this.user.password
      }).then(() => {
        this.$emit('success', this.$i18n.t('users.successfullyCreated'))
        this.$router.push('/login')
      }).catch((error) => {
        this.$emit('error', error.response.data.message)
      })
    },

    userInputIsValid() {
      if (this.user.password !== this.user.confirmPassword) {
        this.$emit('error', this.$i18n.t('users.passwordsDontMatch'))
        return false
      }

      const { error } = schema.validate(this.user)

      if (error) {
        this.$emit('error', error.details[0].message)
        return false
      }

      return true
    }
  }
}
</script>

<style lang="scss">

</style>
