/* refactor the following import when stackoverflow question is resolved: */
/* https://stackoverflow.com/questions/67125889/how-to-resolve-vue-dependency-cycle-with-axios-api-services-and-vuex */
/* until then disable lint rule */
/* eslint-disable import/no-cycle */
import ArtistService from '@/services/ArtistService'
import AlbumService from '@/services/AlbumService'

export default {
  authenticateUser({ commit }, token, user) {
    commit('setToken', token)
    commit('setUser', user)
  },

  async fetchAlbums({ commit }, queryParams) {
    const response = await AlbumService.fetchAlbums(queryParams)
    commit('setAlbums', response.data)
  },

  async fetchArtists({ commit }, queryParams) {
    const response = await ArtistService.fetchArtists(queryParams)
    commit('setArtists', response.data)
  },

  getLoginStatus({ state }) {
    return state.userIsLoggedIn
  }
}
