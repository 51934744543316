import NotFound from '../views/NotFound.vue'
import Artist from '../views/artists/Artist.vue'
import Artists from '../views/artists/Artists.vue'
import EditArtist from '../views/artists/EditArtist.vue'
import EditAlbum from '../views/albums/EditAlbum.vue'
import Login from '../views/users/Login.vue'
import NewAlbum from '../views/albums/NewAlbum.vue'
import Album from '../views/albums/Album.vue'
import Albums from '../views/albums/Albums.vue'
import Register from '../views/users/Register.vue'
import Start from '../views/Start.vue'

export default [
  {
    path: '/',
    redirect: '/login',
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/artists',
    name: 'Artists',
    component: Artists,
    meta: {
      requiresAuth: false,
      titleKey: 'navigation.artists'
    }
  },
  {
    path: '/artists/:id',
    name: 'Artist',
    component: Artist,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/artists/:id/edit',
    name: 'EditArtist',
    component: EditArtist,
    meta: {
      requiresAuth: true,
      titleKey: 'artists.editArtist'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      requiresAuth: false,
      titleKey: 'buttons.login'
    }
  },
  {
    path: '/albums',
    name: 'Albums',
    component: Albums,
    meta: {
      requiresAuth: false,
      titleKey: 'navigation.albums'
    }
  },
  {
    path: '/albums/:id',
    name: 'Album',
    component: Album,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/albums/:id/edit',
    name: 'EditAlbum',
    component: EditAlbum,
    meta: {
      requiresAuth: true,
      titleKey: 'albums.editAlbum'
    }
  },
  {
    path: '/albums/new',
    name: 'NewAlbum',
    component: NewAlbum,
    meta: {
      requiresAuth: true,
      titleKey: 'routeHeaders.newAlbum'
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      requiresAuth: false,
      titleKey: 'users.register'
    }
  },
  {
    path: '/start',
    name: 'start',
    component: Start,
    meta: {
      requiresAuth: true,
      titleKey: 'routeHeaders.start'
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: NotFound,
    meta: {
      requiresAuth: false
    }
  }
]
