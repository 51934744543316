import Api from '@/services/Api'

export default {
  login(bodyParams) {
    return Api().post('/users/login', bodyParams)
  },
  register(bodyParams) {
    return Api().post('users', bodyParams)
  },
}
