<template>
  <div>
    <div>
      <label :for="id">{{label}}</label>
      <input :id="id" type="file" accept="image/*" @change="handleFileChange">
    </div>
    <div>
      <img v-if="previewImage" :id="`${id}-preview`" :src="previewImage">
      <img v-else-if="url" :id="`${id}-preview`" :src="url">
      <p v-else>{{$t("images.noPreview")}}</p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'FileUploader',

  data() {
    return {
      previewImage: null,
      selectedImage: null
    }
  },

  emits: ['imageSelected'],

  methods: {
    handleFileChange(event) {
      /* set the image for upload */
      [this.selectedImage] = event.target.files

      /* read the file as URL for preview */
      const reader = new FileReader()
      reader.onload = (e) => {
        this.previewImage = e.target.result
      }
      reader.readAsDataURL(this.selectedImage)
      this.$emit('imageSelected', this.selectedImage)
    }
  },

  props: {
    id: String,
    label: String,
    url: String
  }
}
</script>

<style lang="scss">

</style>
