<template>
  <div>
    <h1>{{$t($route.meta.titleKey)}}</h1>
    <form id="new-album" @submit.prevent="createAlbum">
    <div>
      <label for="album-title">{{$t("albums.albumTitle")}}</label>
      <input id="album-title" type="text" v-model.trim="albumTitle">
    </div>
    <div>
      <label for="artist-name">{{$t("artists.newArtist")}}</label>
      <input id="artist-name" type="text" v-model.trim="artistName">
    </div>
    <div v-if="artists.length">
      <label for="artist-selection">{{$t("artists.selectArtist")}}</label>
      <select id="artist-selection" v-model="selectedArtistId">
        <option v-for="artist in artists" :key="artist.id" :value="artist.id">
          {{artist.name}}
        </option>
      </select>
    </div>
    <div>
      <label for="release-date">{{$t("albums.releaseDate")}}</label>
      <input id="release-date" type="date" v-model.trim="releaseDate">
    </div>
    <div>
      <label for="tracklist">{{$t("albums.tracklist")}}</label>
      <textarea id="tracklist" rows="4" cols="50" v-model.trim="tracklist">
      </textarea>
    </div>
    <FileUploader id="album-cover" :label="$t(`albums.cover`)"
      @imageSelected="(image) => albumCover = image">
    </FileUploader>
    <button type="submit">{{$t("buttons.create")}}</button>
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ArtistService from '@/services/ArtistService'
import FileUploader from '@/components/FileUploader.vue'
import AlbumService from '@/services/AlbumService'

export default {
  name: 'NewAlbum',

  components: {
    FileUploader
  },

  computed: {
    ...mapState([
      'artists'
    ])
  },

  created() {
    this.initializePage()
  },

  data() {
    return {
      artistName: null,
      albumCover: null,
      albumTitle: null,
      releaseDate: null,
      tracklist: '',
      selectedArtistId: null
    }
  },

  emits: ['error', 'success'],

  methods: {
    async createAlbum() {
      const formData = new FormData()
      formData.append('cover', this.albumCover)

      if (this.artistName) {
        await ArtistService.createArtist({
          name: this.artistName
        }).then((response) => {
          this.selectedArtistId = response.data.id
          this.$emit('success', this.$i18n.t('artists.successfullyCreated'))
        }).catch((error) => {
          this.$emit('error', error.response.data.message)
        })
      }

      if (this.selectedArtistId) {
        formData.append('title', this.albumTitle)
        formData.append('tracklist', this.tracklist)

        if (this.releaseDate) {
          formData.append('releaseDate', this.releaseDate)
        }

        AlbumService.createAlbum(this.selectedArtistId, formData).then(() => {
          this.initializePage()
          this.$emit('success', this.$i18n.t('albums.successfullyCreated'))
        }).catch((error) => {
          this.$emit('error', error.response.data.message)
        })
      }
    },
    async fetchArtists() {
      this.$store.dispatch('fetchArtists').then().catch((error) => {
        console.log(error)
        this.$emit('error', error.response.data.message)
      })
    },
    initializePage() {
      this.resetFields()
      this.fetchArtists()
    },
    resetFields() {
      this.artistName = null
      this.albumCover = null
      this.albumTitle = null
      this.releaseDate = null
      this.selectedArtistId = null
      this.tracklist = ''
    }
  },
  watch: {
    selectedArtistId() {
      if (this.selectedArtistId) {
        this.artistName = null
      }
    }
  }
}
</script>

<style lang="scss">

</style>
