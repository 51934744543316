<template>
  <div>
    <h1>{{$t($route.meta.titleKey)}}</h1>
    <form id="register" @submit.prevent="login">
      <div>
        <label for="user-name">{{$t("users.name")}}</label>
        <input id="user-name" type="text" v-model.trim="user.name" autocomplete="username" required>
      </div>
      <div>
        <label for="user-password">{{$t("users.password")}}</label>
        <input id="user-password" type="password" v-model.trim="user.password" autocomplete="current-password" required>
      </div>
      <button type="submit">{{$t("buttons.login")}}</button>
    </form>
  </div>
</template>

<script>
import Joi from 'joi'
import UserService from '@/services/UserService'

const schema = Joi.object({
  name: Joi.string()
    .trim()
    .alphanum()
    .required(),
  password: Joi.string()
    .trim()
    .required()
})

export default {
  name: 'Login',

  data() {
    return {
      user: {
        name: '',
        password: ''
      }
    }
  },

  emits: ['error', 'success'],

  methods: {
    login() {
      if (!this.userInputIsValid()) {
        return false
      }

      return UserService.login({
        name: this.user.name,
        password: this.user.password
      }).then((response) => {
        this.$store.dispatch('authenticateUser', response.data.token, response.data.user).then(() => {
          const redirectPath = this.$route.query.redirect || '/start'
          this.$router.push(redirectPath)
          this.$emit('success', this.$i18n.t('users.successfullyLoggedIn'))
        }).catch((error) => {
          this.$emit('error', error.response.data.message)
        })
      }).catch((error) => {
        this.$emit('error', error.response.data.message)
      })
    },

    userInputIsValid() {
      const { error } = schema.validate(this.user)

      if (error) {
        this.$emit('error', error.details[0].message)
        return false
      }

      return true
    }
  }
}
</script>

<style lang="scss">

</style>
