import { createStore } from 'vuex'
// refactor the following import when stackoverflow question is resolved:
// https://stackoverflow.com/questions/67125889/how-to-resolve-vue-dependency-cycle-with-axios-api-services-and-vuex
// until then disable lint rule
/* eslint-disable import/no-cycle */
import actions from '@/store/actions'
import createPersistedState from 'vuex-persistedstate'
import mutations from '@/store/mutations'

export default createStore({
  actions,
  mutations,
  plugins: [
    createPersistedState()
  ],
  state: {
    artists: [],
    token: null,
    user: null,
    userIsLoggedIn: false
  },
  /* strict mode should not be used in production mode
     https://vuex.vuejs.org/guide/strict.html#development-vs-production */
  strict: process.env.NODE_ENV !== 'production'
})
