<template>
  <div>
    <h1>{{$t($route.meta.titleKey)}}</h1>
    <div v-if="albums.length">
      <div v-for="album in albums" :key="album.id">
        <img :src="album.coverUrl" width="200">
        <router-link :to="`/albums/${album.id}`" :data-test-album-title="album.title">
          {{album.artistName}} - {{album.title}}
        </router-link>
      </div>
    </div>
    <div v-else>
      <span>{{$t("albums.noAlbumsFound")}}</span>
    </div>
  </div>
</template>

<script>
import AlbumService from '@/services/AlbumService'

export default {
  name: 'Albums',

  created() {
    this.fetchAlbums()
  },

  data() {
    return {
      albums: []
    }
  },

  emits: ['error'],

  methods: {
    fetchAlbums() {
      AlbumService.fetchAlbums()
        .then((response) => {
          this.albums = response.data
        })
        .catch((error) => {
          this.$emit('error', error)
        })
    }
  }
}
</script>

<style lang="scss">

</style>
