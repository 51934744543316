<template>
  <div>
    <div v-if="album">
      <img :src="album.coverUrl">
      <h1>{{album.title}}</h1>
      {{$t("albums.by")}}
      <router-link :to="`/artists/${album.artistId}`">
        {{album.artistName}}
      </router-link>
      <p>{{$t("albums.releaseDate")}}: {{album.releaseDate}}</p>
      <p>{{$t("albums.tracklist")}}: {{album.tracklist}}</p>
      <router-link :to="`/albums/${album.id}/edit`" v-if="$store.state.userIsLoggedIn">
        {{$t("buttons.edit")}}
      </router-link>
      <div>
        <button @click="showNewRecordForm = true">{{$t("records.addRecord")}}</button>
      </div>
      <form v-if="showNewRecordForm" id="new-record" @submit.prevent="createRecord">
        <div>
          <label for="record-color">{{$t("records.recordColor")}}</label>
          <input id="record-color" type="text" v-model.trim="newRecord.color">
        </div>
        <div>
          <label for="record-copiesPressed">{{$t("records.copiesPressed")}}</label>
          <input id="record-copiesPressed" type="text" v-model.trim="newRecord.copiesPressed">
        </div>
        <div>
          <label for="release-date">{{$t("records.releaseDate")}}</label>
          <input id="release-date" type="date" v-model.trim="newRecord.releaseDate">
        </div>
        <FileUploader id="record-image" :label="$t(`records.image`)"
          @imageSelected="(image) => newRecord.image = image">
        </FileUploader>
        <button type="button" @click="cancelRecordCreation">{{$t("buttons.cancel")}}</button>
        <button type="submit">{{$t("buttons.save")}}</button>
      </form>
      <div v-if="album.Records.length">
        <h2>{{$t("records.versions")}}</h2>
        <div v-for="record in album.Records" :key="record.id">
          <img :src="record.imageUrl">
          <div>{{record.color}}</div>
          <div>{{record.copiesPressed}}</div>
          <div>
            <button @click="record.showForm = true">{{$t("buttons.edit")}}</button>
          </div>
          <form v-if="record.showForm" id="new-record" @submit.prevent="saveRecord(record)">
            <div>
              <label for="record-color">{{$t("records.recordColor")}}</label>
              <input id="record-color" type="text" v-model.trim="record.color">
            </div>
            <div>
              <label for="record-copiesPressed">{{$t("records.copiesPressed")}}</label>
              <input id="record-copiesPressed" type="text" v-model.trim="record.copiesPressed">
            </div>
            <div>
              <label for="release-date">{{$t("records.releaseDate")}}</label>
              <input id="release-date" type="date" v-model.trim="record.releaseDate">
            </div>
            <FileUploader id="record-image" :label="$t(`records.image`)"
              @imageSelected="(image) => record.image = image">
            </FileUploader>
            <button type="button" @click="record.showForm = false">{{$t("buttons.cancel")}}</button>
            <button type="submit">{{$t("buttons.save")}}</button>
          </form>
        </div>
      </div>
      <div v-else>
        <span>{{$t("records.noRecords")}}</span>
      </div>
    </div>
    <div v-else>
      <span>{{$t("albums.albumNotFound")}}</span>
    </div>
  </div>
</template>

<script>
import AlbumService from '@/services/AlbumService'
import FileUploader from '@/components/FileUploader.vue'
import RecordService from '@/services/RecordService'

export default {
  name: 'Album',

  components: {
    FileUploader
  },

  created() {
    this.initializePage()
  },

  data() {
    return {
      album: null,
      newRecord: {
        color: null,
        copiesPressed: null,
        image: null,
        releaseDate: null,
      },
      showNewRecordForm: false
    }
  },

  emits: ['error', 'success'],

  methods: {
    cancelRecordCreation() {
      this.resetFields()
      this.showNewRecordForm = false
    },
    createRecord() {
      const formData = new FormData()
      Object.entries(this.newRecord).forEach(([key, value]) => {
        if (this.newRecord[key]) {
          formData.append(key, value)
        }
      })

      RecordService.createRecord(this.album.id, formData).then(() => {
        this.initializePage()
        this.$emit('success', this.$i18n.t('records.successfullyCreated'))
      }).catch((error) => {
        this.$emit('error', error.response.data.message)
      })
    },

    fetchAlbumById(id) {
      AlbumService.fetchAlbumByIdWithRecords(id).then((response) => {
        this.album = response.data
      }).catch((error) => {
        this.$emit('error', error)
      })
    },

    initializePage() {
      this.resetFields()
      this.fetchAlbumById(this.$route.params.id)
    },

    resetFields() {
      this.newRecord = {
        color: null,
        copiesPressed: null,
        image: null,
        releaseDate: null,
      }
      this.showRecordForm = null
    },

    saveRecord(record) {
      const formData = new FormData()
      Object.entries(record).forEach(([key, value]) => {
        if (record[key]) {
          formData.append(key, value)
        }
      })

      RecordService.updateRecord(record.id, formData).then(() => {
        this.initializePage()
        this.$emit('success', this.$i18n.t('records.successfullyUpdated'))
      }).catch((error) => {
        this.$emit('error', error.response.data.message)
      })
    }
  }
}
</script>

<style lang="scss">

</style>
