export default {
  setAlbums(state, albums) {
    state.albums = albums
  },

  setArtists(state, artists) {
    state.artists = artists
  },

  setToken(state, token) {
    state.token = token
    if (token) {
      state.userIsLoggedIn = true
    } else {
      state.userIsLoggedIn = false
    }
  },

  setUser(state, user) {
    state.user = user
  }
}
