/* refactor the following import when stackoverflow question is resolved: */
/* https://stackoverflow.com/questions/67125889/how-to-resolve-vue-dependency-cycle-with-axios-api-services-and-vuex */
/* until then disable lint rule */
/* eslint-disable import/no-cycle */
import Api from '@/services/Api'

export default {
  createAlbum(artistId, bodyParams) {
    return Api().post(`artists/${artistId}/albums`, bodyParams)
  },
  fetchAlbumById(albumId) {
    return Api().get(`albums/${albumId}`)
  },
  fetchAlbumByIdWithRecords(albumId) {
    return Api().get(`albums/${albumId}?include_records=true`)
  },
  fetchAlbums() {
    return Api().get('albums')
  },
  updateAlbum(albumId, bodyParams) {
    return Api().patch(`albums/${albumId}`, bodyParams)
  }
}
