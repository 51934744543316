import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import App from './App.vue'
import router from './router'
import store from './store'
import 'flag-icon-css/css/flag-icons.css'

function loadLocaleMessages() {
  const locales = require.context(
    './locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {}
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

const i18n = createI18n({
  fallbackLocale: 'en',
  locale: navigator.language.substring(0, 2),
  messages: loadLocaleMessages()
})

createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
  .mount('#app')
