<template>
  <div>
    <button v-for="language in languages" :key="language.code" :value="language.code" @click="$root.$i18n.locale = language.code">
      <span :class="`flag-icon flag-icon-${language.country}`"></span>
    </button>
  </div>
  <div class="nav" v-if="$store.state.userIsLoggedIn">
    <router-link to="/start" data-test-start-link>{{$t("navigation.start")}}</router-link> |
    <router-link to="/albums/new">{{$t("navigation.newAlbum")}}</router-link> |
    <router-link to="/albums" data-test-albums-link>{{$t("navigation.albums")}}</router-link> |
    <router-link to="/artists" data-test-artists-link>{{$t("navigation.artists")}}</router-link> |
    <button @click="logout">{{$t("buttons.logout")}}</button>
  </div>
  <div class="nav" v-else>
    <router-link to="/login" data-test-login-link>{{$t("buttons.login")}}</router-link> |
    <router-link to="/register" data-test-register-link>{{$t("users.register")}}</router-link> |
    <router-link to="/albums" data-test-albums-link>{{$t("navigation.albums")}}</router-link> |
    <router-link to="/artists" data-test-artists-link>{{$t("navigation.artists")}}</router-link>
  </div>
  <router-view @error="showError" @success="showSuccess"></router-view>
  <div>{{message}}</div>
  <div class="footer">
    <span>Made by Unkrass - Version {{version}}</span>
  </div>
</template>

<script>
import Languages from '@/locales/availableLanguages'

export default ({
  data() {
    return {
      languages: Languages,
      message: '',
      version: process.env.VUE_APP_VERSION
    }
  },

  methods: {
    logout() {
      this.$store.dispatch('authenticateUser', null, null)
      this.$router.push('/login')
      this.message = this.$i18n.t('users.successfullyLoggedOut')
    },
    showError(message) {
      this.message = message
    },
    showSuccess(message) {
      this.message = message
    }
  }
})
</script>

<style lang="scss">
  html,
  body {
    margin: 0;
    height:100%;
  }

  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    min-height: 100%;
    position: relative;
    text-align: center;
  }

  .nav {
    padding: 30px;
    a {
      font-weight: bold;
      color: #2c3e50;
      &.router-link-exact-active {
        color: #42b983;
      }
    }
  }

  .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
</style>
